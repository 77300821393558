@media screen and (max-width: 375px) {
  html {
    font-size: 16px;
  }

  .button-news span {
    font-size: 120%;
  }

  .newsletter input {
    padding: 0.1rem;
    border: 1px solid #131319;
    border-radius: 1rem;
  }

  header i {
    font-size: 3rem;
  }

  .fa-facebook,
  .fa-envelope {
    font-size: 2rem;
    margin-right: 1rem;
  }
}

@media screen and (min-width: 500px) {
  #description {
    text-align: center;
  }

  iframe {
    width: 500px;
    height: 300px;
  }
  .fa-facebook,
  .fa-envelope {
    font-size: 3rem;
    margin-right: 1.5rem;
  }

  .fa-facebook {
    margin-right: 1rem;
  }
}

@media screen and (min-width: 768px) {
  html {
    font-size: 24px;
  }

  main {
    padding: 5%;
    margin-top: 0;
    border-top: 0;
  }

  header {
    box-shadow: 5px 1px 10px #690000;
  }

  header i {
    font-size: 3rem;
  }

  #bars-div {
    display: none;
  }

  .desktop-menu {
    display: block;
    position: relative;
    width: fit-content;
    margin: 2% auto;
  }

  .desktop-menu li {
    display: inline-block;
    text-align: center;
    margin-right: 1rem;
  }

  .desktop-menu a {
    font-size: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    color: #e9e2d7;
    font-weight: lighter;
    padding: 0.5rem;
  }

  .desktop-menu a:hover {
    color: #b57c43;
  }

  #bio2,
  #bio3 {
    left: 0;
    float: right;
    transform: none;
    margin-left: 1rem;
    border: 2px solid #131319;
  }
}

@media screen and (min-width: 1000px) {
  .mail-popup input {
    width: fit-content;
    font-size: 2rem;
    display: inline-block;
    margin-bottom: 0rem;
    text-align: center;
    margin-right: 1rem;
    background-color: #e9e2d7;
  }

  .mail-popup i {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1200px) {
  main {
    width: 100%;
    height: fit-content;
    overflow: visible;
    /* border-top: 3px solid #ba716c;
    border-bottom: 3px solid #ba716c; */
    padding: 5% 7%;
  }

  /* .fas {
    color: white;
    font-size: 1rem;
  } */

  .fa-times {
    color: black;
    font-size: 2rem;
  }

  h1 {
    font-size: 300%;
    border-bottom: 2px solid #131319a6;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
  }

  h2 {
    font-size: 200%;
    /* border-bottom: 2px solid #131319; */
  }

  h2,
  h3,
  h4 {
    text-align: left;
    width: fit-content;
    margin-bottom: 1.5rem;
  }

  #danakosha-web {
    text-align: center;
    width: 100%;
  }

  p {
    margin-bottom: 2rem;
  }

  #pres {
    margin: 0;
  }

  #description {
    text-align: start;
    /* font-size: 130%; */
  }

  .presentation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 1.5rem;
  }

  .copy {
    grid-row-start: 1;
    grid-column-start: 1;
    width: 100%;
  }

  .pres-tdr {
    grid-row-start: 1;
    grid-column-start: 2;
    justify-self: center;
  }

  .pres-tdr img {
    width: 350px;
    height: auto;
    margin-bottom: 0rem;
    border: 2px solid #131319;
  }

  .short-bio {
    max-width: 70%;
  }

  .long-bio {
    max-width: 80%;
    margin: 0 auto;
  }

  .center-description {
    max-width: 80%;
    margin: 0 auto;
  }

  .button-news {
    display: none;
  }

  footer {
    height: 150px;
    width: 70%;
    margin: 20px auto;
  }

  .footer-grid {
    width: 100%;
  }

  #arrow {
    display: none;
  }

  .fa-chevron-up {
    display: none;
  }

  #bio1 {
    width: 50%;
    height: auto;
    margin-left: 1rem;
    margin-bottom: 1rem;
    float: right;
    border: 2px solid #131319;
  }

  iframe {
    width: 800px;
    height: 515px;
    margin-bottom: 2rem;
  }

  .center-img {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 400px;
  }

  .center-img img {
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
  }

  #danakosha-web {
    margin-top: 2rem;
  }

  #center1 {
    max-width: 100%;
    max-height: auto;
    margin-bottom: 2rem;
    border: 2px solid #131319;
  }
}
@media screen and (min-width: 1500px) {
  #bio1 {
    width: 30%;
    height: auto;
    margin-left: 2rem;
  }

  .long-bio {
    max-width: 70%;
    margin: 0 auto;
  }

  .center-description {
    max-width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1700px) {
  #bio1 {
    width: 30%;
    height: auto;
    margin-left: 2rem;
  }

  #bio2 {
    float: left;
    margin-right: 2rem;
    margin-left: 0;
  }
}
